import { GetStaticProps } from "next";
import LandingPage, { LandingPageProps } from "../components/landing/landing-page";
import { localeFromLangCode } from "../utils/locale-from-header";

export const getStaticProps: GetStaticProps<LandingPageProps> = async (props) => {
  const l = localeFromLangCode(props.params?.lang as string);

  return {
    props: {
      ...l,
    },
    revalidate: 86400, // 24 hours
  };
};

export default function LocalLandingPage(props: LandingPageProps) {
  return <LandingPage {...props} />;
}
